import Vue from 'vue'
const fixed = Vue.directive('fixed', {
  bind: (el, binding) => {
    // const defaultPosition = binding.value;
    const defaultPosition = {
      top: binding.value.top || (window.innerHeight - 200 - binding.value.bottom)
    };
    console.log(window.innerHeight, 'window.innerHeight')
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      el.style.position = 'absolute';
      el.style.transform = defaultPosition.top + 'px';
      const scrollHandle = (e) => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const $htmlStyle = document.querySelector('html').style.transform;
        const htmlScale = $htmlStyle.substring(6, $htmlStyle.length - 1);
        const topDistance = ((scrollTop + defaultPosition.top * htmlScale) / htmlScale).toFixed(
          0
        );
        el.style.top = topDistance + 'px';
      }
      // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
      el.__vueFirxfoxFixed__ = scrollHandle;
      scrollHandle()
      document.addEventListener("scroll", scrollHandle)
    } else {
      el.style.position = 'fixed';
      el.style.top = defaultPosition.top + 'px';
    }
  },
  unbind: (el) => {
    // 解除事件监听
    document.removeEventListener("scroll", el.__vueClickOutside__);
    delete el.__vueFirxfoxFixed__;
  }
})
export default fixed