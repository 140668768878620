import Vue from 'vue'
class CustomerRoute {
  constructor() {
    this.routeParam = {}
  }
  push(param, vueThis) {
    if (typeof param === 'object') {
      this.routeParam[param.path] = param.value;
      vueThis.$router.push({
        path: `${vueThis.$store.state.lang === "zh" ? "" : ("/" + vueThis.$store.state.lang)}${param.path}`,
      });
      this._saveInSeesion();
    } else {
      console.error('参数必须为对象')
    }
  }
  get(path) {
    const sessionParam = JSON.parse(sessionStorage.getItem('routeParam'));
    this.routeParam = sessionParam || {};
    return this.routeParam[path] || null
  }
  clear() {
    this.routeParam = {};
    this._saveInSeesion();
  }
  clearKey(key) {
    delete this.routeParam[key]
    this._saveInSeesion();
  }
  _saveInSeesion() {
    sessionStorage.setItem("routeParam", JSON.stringify(this.routeParam))
  }
}
Vue.prototype.$customerRoute = new CustomerRoute();