<template>
  <div
    id="root__dom"
    :class="{ vi__root: language === 'vi' }"
    style="height: 100%"
    :style="{ fontFamily: language === 'vi' ? 'Times New Roman' : '' }"
  >
    <div id="app">
      <div class="header">
        <div class="header_box">
          <div class="header_box_logo" @click="toTop" style="cursor: pointer">
            <img src="../static/img/header/FFSAY-logo-b.svg" alt="" />
          </div>
          <div class="header_box_titlelist">
            <div
              class="header_box_titlelist_item"
              v-for="(item, index) in titlelist"
              :key="index"
              @click="toTab(index)"
              :class="tabindex === index ? 'tabactive' : ''"
            >
              {{ $t(item) }}
            </div>
          </div>
          <div class="header_box_rightbox">
            <div class="normal_box" @click="changelanguage = !changelanguage">
              <img src="../static/img/header/language.png" alt="" />
              <div class="normal_box_text">{{ $t("app." + language) }}</div>
              <img
                src="../static/img/header/down.png"
                alt=""
                style="margin-left: 2px"
              />
              <div class="change_box" v-if="changelanguage">
                <div class="change_box_top">
                  <img
                    src="../static/img/header/language.png"
                    alt=""
                    style="margin-left: 12px"
                  />
                  <div class="change_box_top_text">
                    {{ $t("app." + language) }}
                  </div>
                  <img
                    src="../static/img/header/up.png"
                    alt=""
                    style="margin-left: 2px"
                  />
                </div>
                <div class="change_box_bottom">
                  <nuxt-link
                    @click.native="switchLang(locale.code)"
                    :to="switchLocalePath(locale.code)"
                    v-for="(locale, i) in showLocales"
                    :key="i"
                  >
                    {{ $t("app." + locale.code) }}
                  </nuxt-link>
                </div>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="header1"></div>
      <div class="content__view">
        <Nuxt />
      </div>
      <div class="footer">
        <div class="footer_box">
          <div class="footer_box_topbox">
            <div class="footer_box_topbox_imgbox">
              <div class="footer_box_topbox_imgbox_top">
                <img src="../static/img/index/FFSAY-logo-w.svg" alt="" />
              </div>
              <div
                class="footer_box_topbox_imgbox_bottom"
                @click="toDownload"
                :class="$i18n.locale === 'ja' ? 'activebottom' : ''"
              >
                <span class="footer_box_topbox_imgbox_bottom_text">
                  {{ $t("footer.download") }}
                </span>
                <img src="../static/img/index/bottomdownload.png" alt="" />
              </div>
            </div>
            <div class="footer_box_topbox_textbox" style="margin-left: 272px">
              <div class="footer_box_topbox_textbox_text">
                {{ $t("footer.gnjs") }}
              </div>
              <div class="footer_box_topbox_textbox_bigtext">
                <div @click="toBanner2">
                  {{ $t("footer.gn") }}
                </div>
              </div>
            </div>
            <div class="footer_box_topbox_textbox">
              <div class="footer_box_topbox_textbox_text">
                {{ $t("footer.wmss") }}
              </div>
              <div class="footer_box_topbox_textbox_bigtext">
                <div @click="showUs = true">
                  {{ $t("footer.gywm") }}
                </div>
                <div @click="showJoin = true">
                  {{ $t("footer.zpxx") }}
                </div>
                <!-- <div >
                  {{ $t('footer.yhxy') }}
                </div>
                <div>
                  {{ $t('footer.yszc') }}
                </div> -->
              </div>
            </div>
            <div class="footer_box_topbox_textbox">
              <div class="footer_box_topbox_textbox_text">
                {{ $t("footer.syggs") }}
              </div>
              <div class="footer_box_topbox_textbox_bigtext">
                <div @click="toDownload">
                  {{ $t("footer.iPhone") }}
                </div>
                <div @click="toDownload">
                  {{ $t("footer.Android") }}
                </div>
                <div @click="toDownload">
                  {{ $t("footer.Mac/Windows") }}
                </div>
                <!-- <div @click="toDownload">
                  {{ $t('footer.wyb') }}
                </div> -->
              </div>
            </div>
            <div class="footer_box_topbox_textbox" style="margin-left: 124px">
              <div class="footer_box_topbox_textbox_text">
                {{ $t("footer.xybz") }}
              </div>
              <div class="footer_box_topbox_textbox_bigtext">
                <div @click="showConcat = true">
                  {{ $t("footer.lxwm") }}
                </div>
              </div>
            </div>
          </div>
          <div class="footer_box_bottombox">
            <div class="footer_box_bottombox_left">© FFSAY LLC</div>
            <div class="footer_box_bottombox_center">
              <div class="footer_box_bottombox_center_item">
                <img src="../static/img/footer/twiter.svg" alt="" />
              </div>
              <div class="footer_box_bottombox_center_item">
                <img src="../static/img/footer/instagram.svg" alt="" />
              </div>
              <div class="footer_box_bottombox_center_item">
                <img src="../static/img/footer/viedo.svg" alt="" />
              </div>
              <div class="footer_box_bottombox_center_item">
                <img src="../static/img/footer/facebook.svg" alt="" />
              </div>
            </div>
            <div
              class="footer_box_bottombox_right"
              @click="changefooter = true"
            >
              <img
                src="../static/img/footer/language.png"
                alt=""
                style="width: 20px; height: 20px; margin-left: 32px"
              />
              <div class="footer_box_bottombox_right_text">
                {{ $t("app." + language) }}
              </div>
              <img
                src="../static/img/footer/down.png"
                alt=""
                style="margin-left: 64px; width: 16px; height: 16px"
              />
              <div class="footer_change" v-if="changefooter">
                <div class="footer_change_top">
                  <nuxt-link
                    @click.native="switchLang(locale.code)"
                    :to="switchLocalePath(locale.code)"
                    v-for="(locale, i) in showLocales"
                    :key="i"
                  >
                    {{ $t("app." + locale.code) }}
                  </nuxt-link>
                </div>
                <div class="footer_change_bottom">
                  <img
                    src="../static/img/footer/blacklan.png"
                    alt=""
                    style="width: 20px; height: 20px; margin-left: 32px"
                  />
                  <div class="footer_change_bottom_text">
                    {{ $t("app." + language) }}
                  </div>
                  <img
                    src="../static/img/footer/up.png"
                    alt=""
                    style="margin-left: 64px; width: 16px; height: 16px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile">
      <div class="mobile_header">
        <img
          src="../static/img/mobile-header/left.png"
          alt=""
          class="mobile_header_left"
          @click="visible = !visible"
        />
        <img
          @click="goHome"
          src="../static/img/header/FFSAY-logo-b.svg"
          alt=""
          class="mobile_header_center"
        />
        <div class="mobile_header_right">
          <div class="language" @click="mlanguage = !mlanguage">
            <img src="../static/img/header/language.png" alt="" />
            {{ $t("app." + $i18n.locale) }}
          </div>
          <img src="../static/img/mobile-header/down.png" alt="" />
          <div class="language_fix" v-if="mlanguage">
            <nuxt-link
              @click.native="switchLang(locale.code)"
              :to="switchLocalePath(locale.code)"
              v-for="(locale, i) in showLocales"
              :key="i"
            >
              {{ $t("app." + locale.code) }}
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="mobile_header1"></div>
      <div class="mobile_view">
        <Nuxt />
      </div>
      <div class="mobile_fix" v-show="visible">
        <div class="mobile_fix_item" style="margin-top: 108px" @click="toIndex">
          <div class="mobile_fix_item_text">{{ $t("header.index") }}</div>
          <img
            src="../static/img/mobile-header/right.png"
            alt=""
            class="mobile_fix_item_img"
          />
        </div>
        <div class="mobile_fix_item" @click="tohDownload">
          <div class="mobile_fix_item_text">{{ $t("header.download") }}</div>
          <img
            src="../static/img/mobile-header/right.png"
            alt=""
            class="mobile_fix_item_img"
          />
        </div>
        <div class="mobile_fix_item" @click="toAbout">
          <div class="mobile_fix_item_text">{{ $t("footer.gywm") }}</div>
          <img
            src="../static/img/mobile-header/right.png"
            alt=""
            class="mobile_fix_item_img"
          />
        </div>
        <div class="mobile_fix_item" @click="toContact">
          <div class="mobile_fix_item_text">{{ $t("footer.lxwm") }}</div>
          <img
            src="../static/img/mobile-header/right.png"
            alt=""
            class="mobile_fix_item_img"
          />
        </div>
      </div>
    </div>
    <t-dialog
      :header="$t('footer.gywm')"
      :visible="showUs"
      :confirmBtn="null"
      :cancelBtn="null"
      :onCloseBtnClick="() => (showUs = false)"
    >
      <div slot="body">
        <div class="us">
          <div class="us_bigtitle">
            {{ $t("footer.gywm") }}
          </div>
          <div class="us_title">
            {{ $t("aboutus.wmdyycx") }}
          </div>
          <div class="us_content">
            {{ $t("aboutus.zqqy30") }}
          </div>
          <div class="us_title">
            {{ $t("aboutus.wmdtd") }}
          </div>
          <div class="us_content">
            {{ $t("aboutus.FFSAY") }}
          </div>
        </div>
      </div>
    </t-dialog>
    <t-dialog
      :header="$t('footer.zpxx')"
      :visible="showJoin"
      :confirmBtn="null"
      :cancelBtn="null"
      :onCloseBtnClick="() => (showJoin = false)"
    >
      <div slot="body">
        <div class="join">
          <div class="join_bigtitle">
            {{ $t("job.jrwm") }}
          </div>
          <div class="join_content" style="margin-top: 88px">
            {{ $t("job.zqq30") }}
          </div>
          <div class="join_content">
            {{ $t("job.FFSAY") }}
          </div>
          <div class="join_content">
            <span>{{ $t("job.yyxkfs1") }}</span
            ><span style="color: #168cff">hr_job@FFSAY.com</span
            ><span>{{ $t("job.yyxkfs2") }}</span>
          </div>
        </div>
      </div>
    </t-dialog>
    <t-dialog
      :header="$t('footer.lxwm')"
      :visible="showConcat"
      :confirmBtn="null"
      :cancelBtn="null"
      :onCloseBtnClick="() => (showConcat = false)"
    >
      <div slot="body">
        <div class="us">
          <div class="us_bigtitle">
            {{ $t("footer.lxwm") }}
          </div>
          <div class="us_title">FFSAY</div>
          <div class="us_content">
            {{ $t("concat.kfdh") }}
          </div>
          <div class="us_content">
            {{ $t("concat.gzyx") }}
          </div>
        </div>
      </div>
    </t-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  head() {
    return {
      title: "FFSAY",
    };
  },
  created() {},
  watch: {
    $route(to) {
      let rule = to.path;
      console.log(rule);
      if (rule.includes("ja")) {
        this.language = "ja";
      } else {
        this.language = "zh";
      }
    },
  },
  mounted() {
    this.initLang();
    window.addEventListener("scroll", this.handleScroll);
    if (this.$route.path.includes("ja")) {
      this.language = "ja";
    } else if (this.$route.path.includes("zh")) {
      this.language = "zh";
    } else if (this.$route.path.includes("en")) {
      this.language = "en";
    } else {
      this.language = "vi";
    }
    const wrapper = document.querySelector(".normal_box");
    const footer = document.querySelector(".footer_box_bottombox_right");
    const mwrapper = document.querySelector(".language");
    // 点击父容器外面的元素时，隐藏.box和.overlay
    document.addEventListener("click", (event) => {
      if (!wrapper.contains(event.target)) {
        this.changelanguage = false;
      }
      if (!footer.contains(event.target)) {
        this.changefooter = false;
      }
      if (!mwrapper.contains(event.target)) {
        this.mlanguage = false;
      }
    });
  },
  data() {
    return {
      showlanguage: "",
      mlanguage: false,
      visible: false,
      changefooter: false,
      changelanguage: false,
      language: "vi",
      showConcat: false,
      showJoin: false,
      showUs: false,
      tabindex: 0,
      inputtext: "",
      dialogVisible: false,
      searchIndex: "",
      nowIndex: "",
      lang: [],
      headerbg: false,
      titlelist: ["header.index", "header.product", "header.download"],
      form: {
        name: "",
        phone: "",
        content: "",
      },
    };
  },
  computed: {
    showLocales() {
      return this.$i18n.locales;
    },
  },
  methods: {
    goHome() {
      location.href = this.language === "zh" ? "/" : `/${this.language}`;
    },
    initLang() {
      const language = navigator.language || navigator.browserLanguage;
      const replaceLang = {
        "zh-CN": () => {
          this.language = "zh";
          if (!this.$route.path.includes("zh")) {
            location.href = "/zh" + this.$route.fullPath;
          }
        },
        "ja-JP": () => {
          this.language = "ja";
          if (!this.$route.path.includes("ja")) {
            location.href = "/ja" + this.$route.fullPath;
          }
        },
        vi: () => {
          this.language = "vi";
          if (this.$route.path !== '/') {
            location.href = this.$route.fullPath;
          }
        },
      };
      if (!window.sessionStorage.getItem("load")) {
        if (language in replaceLang) {
          replaceLang[language]();
          window.sessionStorage.setItem("load", "loaded");
        }
      }
    },
    toTop() {
      const banner1 = document.querySelector(".index_banner1").offsetTop - 75;
      window.scrollTo(0, banner1);
    },
    toDownload() {
      const Download = document.querySelector(".index_download").offsetTop;
      window.scrollTo(0, Download - 72);
    },
    toContact() {
      const path = `${
        this.$i18n.locale === "zh" ? "" : "/" + this.$i18n.locale
      }${"/contact"}`;
      this.$router.push({
        path: path,
      });
      console.log(this.$store.state.lang);
      this.visible = false;
    },
    toAbout() {
      const path = `${
        this.$i18n.locale === "zh" ? "" : "/" + this.$i18n.locale
      }${"/about"}`;
      this.$router.push({
        path: path,
      });
      this.visible = false;
    },
    tohDownload() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/android/.test(userAgent)) {
        // 手机是安卓设备
        console.log("安卓");
        const path = `${
          this.$i18n.locale === "zh" ? "" : "/" + this.$i18n.locale
        }${"/android"}`;
        this.$router.push({
          path: path,
        });
      } else if (/iphone|ipad|ipod/.test(userAgent)) {
        // 手机是苹果设备
        console.log("苹果");
        const path = `${
          this.$i18n.locale === "zh" ? "" : "/" + this.$i18n.locale
        }${"/ios"}`;
        this.$router.push({
          path: path,
        });
      } else {
        // 其他设备
        console.log("其他设备");
      }
      this.visible = false;
    },
    toIndex() {
      const path = `${
        this.$i18n.locale === "zh" ? "" : "/" + this.$i18n.locale
      }${"/"}`;
      this.$router.push({
        path: path,
      });
      this.visible = false;
    },
    toBanner2() {
      const banner2 = document.querySelector(".index_banner2").offsetTop - 75;
      window.scrollTo(0, banner2);
    },
    switchLang(lang) {
      console.log(lang);
      this.$store.commit("SET_LANG", lang);
      this.language = lang;
      this.changelanguage = false;
      this.changefooter = false;
    },
    handleScroll() {
      const banner1 = document.querySelector(".index_banner1").offsetTop - 75;
      const banner2 = document.querySelector(".index_banner2").offsetTop - 75;
      const banner8 = document.querySelector(".index_banner8").offsetTop - 75;
      const download = document.querySelector(".index_download").offsetTop - 75;
      const scrollDistance = window.scrollY || window.pageYOffset;
      const tablist = document.querySelectorAll(".header_box_titlelist_item");
      if (
        scrollDistance <= banner1 ||
        (scrollDistance > banner1 && scrollDistance < banner2)
      ) {
        tablist[0].classList.add("tabactive");
        tablist.forEach((element, index) => {
          if (index !== 0 && element.classList.contains("tabactive")) {
            element.classList.remove("tabactive");
          }
        });
      } else if (scrollDistance >= banner2 && scrollDistance <= banner8) {
        tablist[1].classList.add("tabactive");
        tablist.forEach((element, index) => {
          if (index !== 1 && element.classList.contains("tabactive")) {
            element.classList.remove("tabactive");
          }
        });
      } else if (scrollDistance > banner8 && scrollDistance <= download) {
        tablist[2].classList.add("tabactive");
        tablist.forEach((element, index) => {
          if (index !== 2 && element.classList.contains("tabactive")) {
            element.classList.remove("tabactive");
          }
        });
      }
    },
    toTab(index) {
      const banner1 = document.querySelector(".index_banner1").offsetTop - 75;
      const banner2 = document.querySelector(".index_banner2").offsetTop - 75;
      const download = document.querySelector(".index_download").offsetTop - 75;
      // const domnews = document.querySelector('.index-news').offsetTop
      if (index === 0) {
        window.scrollTo(0, banner1);
      } else if (index === 1) {
        window.scrollTo(0, banner2);
      } else if (index === 2) {
        window.scrollTo(0, download);
      }
      // else if (index === 3) {
      //   window.scrollTo(0, domProduct)
      // } else if (index === 4) {
      //   window.scrollTo(0, domnews)
      // }
      //  location.href= '#' + index.toString()
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  min-width: 1300px;
  overflow: auto;
}

.header {
  height: 72px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  background: rgba(237, 241, 254, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #d1ddfd;

  &_box {
    margin: auto;
    height: 100%;
    width: calc(1300px - 136px);
    padding: 0 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_logo {
      width: 159px;
      height: 42px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &_titlelist {
      width: 400px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_item {
        font-size: 16px;
        font-weight: 600;
        opacity: 0.5;
        color: #0a1d4c;
        cursor: pointer;
      }

      .tabactive {
        opacity: 1;
      }
    }

    &_rightbox {
      width: 180px;

      .normal_box {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0px 12px;

        &_text {
          margin-left: 12px;
          font-size: 16px;
          color: #0a1d4c;
        }

        .change_box {
          position: absolute;
          width: 100%;
          background-color: #fff;
          border-radius: 10px;
          left: 0;
          top: -11px;
          &_top {
            width: 100%;
            display: flex;
            align-items: center;
            height: 43px;

            &_text {
              margin-left: 12px;
              font-size: 16px;
              color: #0a1d4c;
            }
          }

          &_bottom {
            display: flex;
            flex-direction: column;
            margin-left: 38px;

            a {
              padding: 16px 0px;
              color: #0a1d4c;
              text-decoration: none;
              font-size: 16px;
            }

            a:hover {
              color: #0483ff;
            }
          }
        }

        .overlay {
          position: fixed;
          left: -16px;
          top: -5px;
          z-index: 999;
          display: none;
          background-color: red;
          pointer-events: none;
          /* 防止阻挡下层元素的点击事件 */
        }

        .overlay.active {
          display: block;
        }
      }
    }
  }
}

.mobile_header {
  display: none;
}

.header1 {
  height: 73px;
  width: 100%;
  background: rgba(237, 241, 254, 0.8);
  backdrop-filter: blur(10px);
}

.content__view {
  width: 100%;
  margin: 0 auto;
}

.footer {
  width: 100%;
  height: 418px;
  background-color: #2d3237;

  &_box {
    width: 1300px;
    padding: 0 68px;
    margin: auto;
    overflow: hidden;
    box-sizing: border-box;
    &_topbox {
      height: 315px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;

      &_imgbox {
        margin-top: 59px;

        &_top {
          width: 159px;
          height: 42px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &_bottom {
          padding: 0 33px;
          height: 52px;
          margin-top: 101px;
          background: #ffffff;
          border-radius: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &_text {
            font-size: 18px;
            font-weight: 600;
            color: #101b21;
            max-width: 108px;
          }

          img {
            margin-left: 10px;
          }
        }
        .activebottom {
          width: 134px;
        }
      }

      &_textbox {
        margin-top: 56px;
        margin-left: 146px;

        &_text {
          font-size: 13px;
          opacity: 0.8;
          color: #ffffff;
        }

        &_bigtext {
          max-width: 100px;
          margin-top: 29px;

          div {
            cursor: pointer;
            line-height: 33px;
            margin-bottom: 12px;
            font-size: 20px;
            color: #ffffff;
          }

          div:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &_bottombox {
      height: 98px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_left {
        font-size: 12px;
        font-family: ArialMT;
        color: #ffffff;
        opacity: 0.5;
      }

      &_center {
        height: 48px;
        margin-left: 268px;
        display: flex;

        &_item {
          margin-right: 24px;
          width: 48px;
          height: 48px;
          border: 1px solid #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      &_right {
        width: 232px;
        height: 58px;
        border-radius: 29px;
        border: 2px solid #ffffff;
        margin-left: 218px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &_text {
          font-size: 16px;
          color: #ffffff;
          margin-left: 12px;
        }

        .footer_change {
          position: absolute;
          width: 236px;
          background-color: #fff;
          border-radius: 29px;
          left: -2px;
          bottom: -2px;
          padding-bottom: 10px;

          &_bottom {
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 10px;
            &_text {
              font-size: 16px;
              color: #2d3237;
              margin-left: 14px;
            }
          }

          &_top {
            display: flex;
            flex-direction: column;
            margin-left: 34px;

            a {
              padding: 16px 0px;
              color: #0a1d4c;
              text-decoration: none;
            }

            a:hover {
              color: #0483ff;
            }
          }
        }
      }
    }
  }
}

.mobile_footer {
  display: none;
}

/deep/ .t-dialog {
  width: 900px;
}

.us {
  height: 460px;
  overflow-y: auto;

  &_bigtitle {
    margin-top: 50px;
    font-size: 50px;
    font-weight: 600;
    color: #323233;
  }

  &_title {
    font-size: 22px;
    font-weight: 600;
    color: #323233;
    margin-top: 50px;
  }

  &_content {
    margin-top: 20px;
    font-size: 14px;
    color: #323233;
    line-height: 30px;
  }
}

.join {
  height: 460px;
  overflow-y: auto;

  &_bigtitle {
    margin-top: 50px;
    font-size: 50px;
    font-weight: 600;
    color: #323233;
  }

  &_content {
    margin-top: 20px;
    font-size: 14px;
    color: #323233;
    line-height: 30px;
  }
}

.join::-webkit-scrollbar {
  background: #aeafb1;
  width: 6px;
}

.join::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: transparent;
}

.us::-webkit-scrollbar {
  background: #aeafb1;
  width: 6px;
}

.us::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: transparent;
}

.mobile_view {
  display: none;
}

@media only screen and (max-width: 600px) {
  #app {
    display: none;
  }

  .mobile {
    display: block;
  }

  .mobile_header {
    display: flex;
    width: calc(100% - 40px);
    padding: 0px 20px;
    height: 48px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;

    &_left {
      width: 24px;
      height: 24px;
    }

    &_center {
      width: 99px;
      height: 28px;
      margin-left: 20px;
      position: absolute;
      left: 30%;
    }

    &_right {
      display: flex;
      align-items: center;
      position: relative;

      .language {
        font-size: 17px;
        color: #000000;
      }

      .language_fix {
        position: fixed;
        right: 4px;
        top: 52px;
        width: 120px;
        background-color: #fff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        box-shadow: 0 0 6px 0px #cfcfcf;
        a {
          font-size: 14px;
          color: #0a1d4c;
          text-decoration: none;
          margin-left: 16px;
          margin-top: 16px;
        }
      }

      img {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }

  .mobile_header1 {
    display: block;
    width: 100%;
    height: 48px;
  }

  .mobile_view {
    display: block;
  }

  .mobile_fix {
    width: 100%;
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: calc(100vh - 48px);
    z-index: 100;
    backdrop-filter: blur(10px);
    /* 设置模糊程度 */
    background-color: rgba(255, 255, 255, 0.5);
    /* 设置遮罩层颜色和透明度 */
    display: flex;
    flex-direction: column;
    align-items: center;

    &_item {
      margin-top: 64px;
      display: flex;
      align-items: center;

      &_text {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        line-height: 28px;
      }

      &_img {
        margin-left: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .content__view {
    display: none;
  }
}
</style>
