
import ja from '../lang/ja_JP.js'
import zh from '../lang/zh-CN.js'
import vi from '../lang/vi_VI.js'
import en from '../lang/en_EN.js'

export default {
  baseUrl: 'https://api.ffsay.com',
  // baseUrl: 'http://192.168.3.48:8094',
}

export const I18N = {
  locales: [
    {
      code: 'ja',
      iso: 'ja_JP',
      name: '日语'
    },
    {
      code: 'en',
      iso: 'en-US',
      name: '英语'
    },
    {
      code: 'vi',
      iso: 'vi',
      name: '越南语'
    },
    {
      code: 'zh',
      iso: 'zh-CN',
      name: '简体'
    },
  ],
  defaultLocale: 'vi',
  vueI18n: {
    fallbackLocale: 'vi',
    messages: { ja, zh, vi, en }
  }
}
