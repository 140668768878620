import Vue from 'vue'
// 重写element message提示函数，解决Firefox火狐浏览器滚动到底部时提示框没有定位到当前窗口的问题
Vue.prototype.$customerMsg = () => {
  if (navigator.userAgent.indexOf("Firefox") > 0) {
    Vue.prototype.$nextTick(() => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const $htmlStyle = document.querySelector('html').style.transform;
      const htmlScale = $htmlStyle.substring(6, $htmlStyle.length - 1)
      const topDistance = ((scrollTop + 20) / htmlScale).toFixed(0);
      Array.from(document.querySelectorAll('.el-message')).forEach(ele => {
        ele.style.top = topDistance + 'px'
      })
    })
  }
  return Vue.prototype.$message;
};